import React from 'react';
import './App.css';
import SignUp from './SignUp'
import SignedUp from './SignedUp'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {main:'#ffaec9',
    contrastText: "#fff" //button text white instead of black
  },
    secondary: {main:'#ffffff'},
  },
  status: {
    danger: 'orange',
  }
});

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Router>
        <Switch>
          <Route exact path="/">
            <SignUp/>
          </Route>
          <Route exact path="/register">
            <SignUp/>
          </Route>
          <Route path="/success">
            <SignedUp />
          </Route>
        </Switch>
      </Router>
    </MuiThemeProvider>
  );
}

export default App;
