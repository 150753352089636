import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper, Typography, Box,
    Grid, Link, TextField, CssBaseline, Button} from '@material-ui/core';
import logo from './images/logo192.png';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Text Kit
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    //padding: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper2: {
    padding: theme.spacing(8),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function handleSubmit(event) {
    var fname = event.target.firstName.value
    var lname = event.target.lastName.value
    var email = event.target.email.value
    fetch('https://www.michaeldbrant.com/api/email?email_address='+ email +'&first_name='+fname +'&last_name='+lname, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
        }
    }).then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Something went wrong');
        }
      })
      .then((responseJson) => {
        console.log(responseJson)
        window.location = '/success';
      })
      .catch((error) => {
        console.log(error)
      });

    event.preventDefault(); 
    }

export default function SignUp() {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper className={classes.paper2}>
      <div className={classes.paper}>
        <img src={logo} alt="Logo" width="100" height="100"/>
        <Typography component="h1" variant="h5">
          Text Kit
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} >
                <Typography component="h4" variant="body">
                    We'll send you a key to unlock Text Kit
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Get API Key 🔑
          </Button>
        </form>
      </div>
      </Paper>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}