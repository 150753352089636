import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Paper, Typography, Box, Link, 
    CssBaseline} from '@material-ui/core';
import logo from './images/logo192.png';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Text Kit
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    //marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper2: {
    padding: theme.spacing(8),
  },
  text: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: "center",
  },
}));

function goback(){
    window.location = '/register';
}
export default function SignedUp() {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Paper className={classes.paper2}>
        <div className={classes.paper}>
            <img src={logo} alt="Logo" width="100" height="100"/>
            <Typography component="h1" variant="h5">
            Text Kit
            </Typography>
        </div>
      <span className={classes.text}>
      <Typography component="h5" variant="h5">
          Check your inbox! 
      </Typography>
      </span>
      <span className={classes.text}>
      <Typography component="h5" variant="h3">
      🔑 📬
      </Typography>
      </span>
      <div className={classes.text}>
      <Typography component="body1" variant="body1" >
          If you don't see an email, try a {' '} 
          <Link color="primary" onClick={goback} href="#" underline="none" target="_blank">
              different
            </Link>
            {' '}
            email address.
      </Typography>
    
      </div>
      </Paper>
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  );
}